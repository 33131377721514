exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-markdown-remark-frontmatter-path-js": () => import("./../../../src/pages/about/{MarkdownRemark.frontmatter__path}.js" /* webpackChunkName: "component---src-pages-about-markdown-remark-frontmatter-path-js" */),
  "component---src-pages-admissions-markdown-remark-frontmatter-path-js": () => import("./../../../src/pages/admissions/{MarkdownRemark.frontmatter__path}.js" /* webpackChunkName: "component---src-pages-admissions-markdown-remark-frontmatter-path-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-markdown-remark-frontmatter-path-js": () => import("./../../../src/pages/donate/{MarkdownRemark.frontmatter__path}.js" /* webpackChunkName: "component---src-pages-donate-markdown-remark-frontmatter-path-js" */),
  "component---src-pages-head-of-school-blog-js": () => import("./../../../src/pages/head-of-school-blog.js" /* webpackChunkName: "component---src-pages-head-of-school-blog-js" */),
  "component---src-pages-how-we-teach-and-learn-markdown-remark-frontmatter-path-js": () => import("./../../../src/pages/how-we-teach-and-learn/{MarkdownRemark.frontmatter__path}.js" /* webpackChunkName: "component---src-pages-how-we-teach-and-learn-markdown-remark-frontmatter-path-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internships-markdown-remark-frontmatter-path-js": () => import("./../../../src/pages/internships/{MarkdownRemark.frontmatter__path}.js" /* webpackChunkName: "component---src-pages-internships-markdown-remark-frontmatter-path-js" */),
  "component---src-pages-markdown-remark-frontmatter-path-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__path}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-path-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-middle-school-book-blog-js": () => import("./../../../src/pages/middle-school-book-blog.js" /* webpackChunkName: "component---src-pages-middle-school-book-blog-js" */),
  "component---src-pages-reading-resources-markdown-remark-frontmatter-path-js": () => import("./../../../src/pages/reading-resources/{MarkdownRemark.frontmatter__path}.js" /* webpackChunkName: "component---src-pages-reading-resources-markdown-remark-frontmatter-path-js" */)
}

